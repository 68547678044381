<div class="page-wrapper" [ngClass]="{'embed': isEmbedded}">
  <div class="content-wrapper">
    <div class="left-column">
      <h1>{{ pageContent?.[0]?.dce_intro?.main_title }}</h1>
      <div class="cs-inner-html-18" [innerHTML]="pageContent?.[0].dce_intro.text"></div>
    </div>
    <div class="right-column">
      <div class="name-wrapper">
        <div class="main-heading modify-text" *ngIf="pageContentContact">
          <h2 [innerHtml]="pageContentContact[0]?.dce_page_introduction?.title"></h2>
          <div [innerHtml]="pageContentContact[0]?.dce_page_introduction?.text"
              class="cs-inner-html-18"
            ></div>
        </div>
        <form
          class="form-wrapper"
          [formGroup]="personalInfoForm"
          *ngIf="personalInfoForm"
        >
          <app-form-group
            label="First Name"
            id="firstName"
            [required]="true"
            formControlName="firstName"
          >
          </app-form-group>
          <app-form-group
            [required]="true"
            label="Last Name"
            id="lastName"
            formControlName="lastName"
          >
          </app-form-group>
          <div>
            <div class="contact-form">
              <app-form-group
                label="Phone number"
                [required]="true"
                id="primaryPhone"
                formControlName="primaryPhone"
                type="phone"
              ></app-form-group>
              <app-form-group
                label="Email"
                [required]="true"
                id="primaryEmail"
                formControlName="primaryEmail"
                type="email"
              ></app-form-group>
              <app-checkbox
              formControlName="contactPreference"
              [label]="
                pageContentContact?.[1]?.dce_contact_form_data?.agreement_text
              "
              [labelHTML]="
                pageContentContact?.[1]?.dce_contact_form_data?.agreement_text
              "
            ></app-checkbox>
            </div>
          </div>
          <div class="button-holder">
            <app-button
              text="Start my estimate"
              [isUppercase]="true"
              [disabled]="!personalInfoForm.valid"
              type="filled"
              [rightIconSrc]="isEmbedded ? '/assets/icons/arrow-right-black.svg' : ''"
              [handleClick]="onClickNext"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="offer-bar" *ngIf="pageContent && pageContent[0]?.dce_intro?.offer">
    <div class="frame">
      <div class="icon">
        <img src="assets/icons/offer-icon.svg" alt="">
      </div>
      <div class="offer-bar-description">
        <strong class="offer-title">{{pageContent[0]?.dce_intro?.offer}}</strong>
        <p>{{pageContent[0]?.dce_intro?.offer_description}}</p>
      </div>
    </div>
  </div>
</div>
